import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

class Navigation {
    constructor (element) {
        this.$navigation = element;
        this.$menuToggle = this.$navigation.querySelector('[id="menu-toggle"]');
        this.$bookmarksToggle = this.$navigation.querySelector('[id="bookmarks-toggle"]');
        this.$navigationScroller = this.$navigation.querySelector('[data-siteheader="scroller"]');
    }

    initialize () {
        if (this.$navigation) {
            this.setEvents();
        }
    }

    setEvents () {
        this.$menuToggle.addEventListener('change', () => {
            if (this.$menuToggle.checked) {
                if (this.$bookmarksToggle.checked) {
                    this.$bookmarksToggle.checked = false;
                }

                this.$navigation.classList.add('has--menu-open');
                disableBodyScroll(this.$navigationScroller);
            } else {
                this.$navigation.classList.remove('has--menu-open');
                enableBodyScroll(this.$navigationScroller);
            }
        });

        this.$bookmarksToggle.addEventListener('change', () => {
            if (this.$bookmarksToggle.checked) {
                if (this.$menuToggle.checked) {
                    this.$menuToggle.checked = false;
                }

                this.$navigation.classList.add('has--menu-open');
                disableBodyScroll(this.$navigationScroller);
            } else {
                this.$navigation.classList.remove('has--menu-open');
                enableBodyScroll(this.$navigationScroller);
            }

            document.activeElement.blur();
        });
    }
}

export { Navigation };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $navigation = $context.querySelector('[data-header="root"]');
        if ($navigation) {
            const navigation = new Navigation($navigation);
            navigation.initialize();
        }
    }
});
